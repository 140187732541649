/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:20:54 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-Th 09:43:13
 */
<template>
  <div class="question_details">
    <div class="cont">
      <h3 class="personal_title">
        <el-popover
          placement="bottom"
          title=""
          width="200"
          trigger="hover"
          content=""
        >
          <el-scrollbar style="height: 210px">
            <div
              class="courseList"
              v-for="item in courseList"
              :key="item.CourseId"
              @click="handleChange(item)"
            >
              {{ item.CourseName }}
            </div>
          </el-scrollbar>
          <div slot="reference" class="courseList_hover">{{ selectVal }}</div>
        </el-popover>
        <!-- <el-select v-model="selectVal" @change="handleChange" placeholder="请选择">
          <el-option
            v-for="item in courseList"
            :key="item.CourseId"
            :label="item.CourseName"
            :value="item.CourseId+','+item.IsActive">
          </el-option>
        </el-select> -->
        <ul class="practice" v-if="selectVal.includes('英语')">
          <li
            v-for="(par, i) in practices"
            :key="i"
            :class="practicesIndex == par.id ? 'active' : ''"
            @click="practicesClick(par)"
            v-if="selectVal.includes('英语') && par.id > 5"
          >
            <div>
              <i
                :class="
                  practicesIndex == par.id
                    ? par.icon + ' c_3A78F9'
                    : par.icon + ' c_707070'
                "
                color="#E2EBFE"
                v-if="par.icon"
              ></i>
              <img
                v-else
                :src="
                  require('@/assets/images/' +
                    (practicesIndex == par.id
                      ? par.img + '_checked'
                      : par.img) +
                    '.png')
                "
                width="14"
                alt=""
              />
              <span :class="practicesIndex == par.id ? 'active' : ''">{{
                par.name
              }}</span>
            </div>
          </li>
        </ul>
        <ul class="practice" v-if="!selectVal.includes('英语')">
          <li
            v-for="(par, i) in practices"
            :key="i"
            :class="practicesIndex == par.id ? 'active' : ''"
            @click="practicesClick(par)"
            v-if="!selectVal.includes('英语') && par.id <= 5"
          >
            <div>
              <i
                :class="
                  practicesIndex == par.id
                    ? par.icon + ' c_3A78F9'
                    : par.icon + ' c_707070'
                "
                color="#E2EBFE"
              ></i>
              <span :class="practicesIndex == par.id ? 'active' : ''">{{
                par.name
              }}</span>
            </div>
          </li>
        </ul>
      </h3>
      <div
        class="rollUp"
        v-if="
          practicesIndex == 1 ||
          practicesIndex == 3 ||
          practicesIndex == 4 ||
          practicesIndex == 5
        "
      >
        <div
          class="rollUp_top"
          v-if="
            practicesIndex != 5 && practicesIndex != 3 && practicesIndex != 4
          "
        >
          <span
            v-for="(rl, i) in contacts"
            :key="i"
            :class="contactsIndex == i ? 'active' : ''"
            @click="contactsClick(rl, i)"
            >{{ rl }}</span
          >
        </div>
        <div class="rollUp_cont rollUp_cont1" v-if="practicesIndex == 1">
          <div v-if="list.length > 0">
            <el-row
              type="flex"
              justify="center"
              v-for="(li, i) in list"
              :key="i"
              @click.native="parcticeClick(li)"
            >
              <el-col :span="20">
                <div class="name">{{ li.Name }}</div>
                <div class="count">共{{ li.QuestionCount }}题</div>
              </el-col>
              <!-- <el-col :span="8">题目数：{{ li.QuestionCount }}</el-col> -->
              <el-col :span="4" class="text_align_right">
                <p class="c_1288F4">开始练习</p>
              </el-col>
            </el-row>
          </div>
          <div v-else>
            <el-row>
              <el-col :span="24">
                <no-data></no-data>
              </el-col>
            </el-row>
          </div>
        </div>
        <div
          class="rollUp_cont"
          style="padding-top: 0"
          v-if="practicesIndex == 3 || practicesIndex >= 4"
        >
          <div class="rollUp_top" v-show="practicesIndex == 3">
            <span
              v-for="(qc, i) in questionCategory"
              :key="i"
              :class="qcIndex == i ? 'active' : ''"
              @click="qcategoryClick(qc, i, 1)"
              >{{ qc }}</span
            >
          </div>
          <div class="rollUp_top" v-show="practicesIndex == 4">
            <span
              v-for="(qc, i) in wordsCategory"
              :key="i"
              :class="wordsqcIndex == i ? 'active' : ''"
              @click="qcategoryClick(qc, i, 2)"
              v-if="selectVal.includes('英语') == false && i != 1 && i != 4"
              >{{ qc }}</span
            >
          </div>
          <el-tree
            :indent="25"
            default-expand-all
            highlight-current
            :expand-on-click-node="false"
            :data="list"
            :props="defaultProps"
            @node-click="handleNodeClick"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span v-if="data.li_attr.questionCount > 0" class="tree_right">
                <!-- <el-progress
                  :percentage="
                    (data.li_attr.AnswerCount / data.li_attr.questionCount) *
                    100
                  "
                  :color="customColor"
                ></el-progress> -->
                <div class="proportion">
                  {{ data.li_attr.AnswerCount }}/{{
                    data.li_attr.questionCount
                  }}
                </div>
              </span>
            </span>
          </el-tree>
        </div>
        <div class="rollUp_cont" v-if="practicesIndex == 5">
          <el-row type="flex" justify="center">
            <el-col :span="12">题型</el-col>
            <el-col :span="8">题目数</el-col>
            <el-col :span="4" class="text_align_right"></el-col>
          </el-row>
          <div v-if="list.length > 0">
            <el-row
              type="flex"
              justify="center"
              v-for="(li, i) in list"
              :key="i"
              @click.native="parcticeClick(li)"
            >
              <el-col :span="12">{{ li.Name }}</el-col>
              <el-col :span="8">{{ li.QuestionCount }}</el-col>
              <el-col :span="4" class="text_align_right">
                <p class="c_1288F4">开始练习</p>
              </el-col>
            </el-row>
          </div>
          <div v-else>
            <el-row>
              <el-col :span="24">
                <no-data></no-data>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-if="list.length > 0">
          <el-pagination
            v-if="practicesIndex == 1 || practicesIndex == 5"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
      <div class="rollUp" v-if="practicesIndex > 5">
        <div class="he_10" v-if="practicesIndex === 7"></div>
        <div class="rollUp_top_first" v-if="practicesIndex === 7">
          <div class="day" @click="goToCalendar">
            <span>{{ studySituation.taskCompleteCount }}</span
            >天
          </div>
          <div class="set">
            <div @click="setStudy">
              设置学习目标
              <img
                src="@/assets/images/icon_set.png"
                width="16"
                alt=""
                srcset=""
              />
              <i class="el-icon-arrow-right icon_right"></i>
            </div>
            <div v-if="studySituation.todayIsStudy == 0" @click="handStudy">
              立即学习
              <img
                src="@/assets/images/icon_pen.png"
                width="16"
                alt=""
                srcset=""
              />
              <i class="el-icon-arrow-right icon_right"></i>
            </div>
            <div v-else @click="goToCalendar">
              已打卡
              <img
                src="@/assets/images/icon_canler.png"
                width="16"
                alt=""
                srcset=""
              />
              <i class="el-icon-arrow-right icon_right"></i>
            </div>
            <div v-if="refreshBtnShow" @click="resetEnglishPractice()" style="margin-left: 80px;">
              重新学习
              <img
                src="@/assets/images/icon_canler.png"
                width="16"
                alt=""
                srcset=""
              />
              <i class="el-icon-arrow-right icon_right"></i>
            </div>
          </div>
        </div>
        <div class="he_10" v-if="practicesIndex === 7"></div>
        <div class="rollUp_cont">
          <div v-if="list.length > 0 && practicesIndex == 6">
            <el-row
              type="flex"
              justify="center"
              v-for="(li, i) in list"
              :key="i"
              @click.native="parcticeClickAfter(li)"
            >
              <el-col :span="16" style="padding-left: 10px">
                {{ li.CategoryName }}
                （
                <span class="c_999999">
                  {{ li.DoQuestionCount }}/{{ li.AllQuestionCount }}
                </span>
                ）
              </el-col>
              <el-col
                :span="8"
                class="text_align_right"
                style="margin-top: 8px"
              >
                <img src="@/assets/images/icon_per.png" width="18" alt="" />
              </el-col>
            </el-row>
          </div>
          <div v-if="list.length > 0 && practicesIndex == 7">
            <el-row
              type="flex"
              justify="center"
              v-for="(li, i) in list"
              :key="i"
              @click.native="parcticeClickVocabulary(li)"
            >
              <el-col :span="16" style="padding-left: 10px">
                {{ li.CategroyName }}
                （
                <span class="c_999999">
                  {{ li.DoCount }}/{{ li.AllCount }}
                </span>
                ）
              </el-col>
              <el-col
                :span="8"
                class="text_align_right"
                style="margin-top: 8px"
              >
                <img src="@/assets/images/icon_per.png" width="18" alt="" />
              </el-col>
            </el-row>
          </div>
          <div v-if="list.length > 0 && practicesIndex == 8">
            <el-row
              type="flex"
              justify="center"
              v-for="(li, i) in list"
              :key="i"
              @click.native="parcticeClickPattern(li)"
            >
              <el-col :span="16" style="padding-left: 10px">
                {{ li.CategroyName }}
                （
                <span class="c_999999">
                  {{ li.DoCount }}/{{ li.AllCount }}
                </span>
                ）
              </el-col>
              <el-col
                :span="8"
                class="text_align_right"
                style="margin-top: 8px"
              >
                <img src="@/assets/images/icon_per.png" width="18" alt="" />
              </el-col>
            </el-row>
          </div>
          <!-- <div v-else>
            <el-row>
              <el-col :span="24">
                <no-data></no-data>
              </el-col>
            </el-row>
          </div> -->
        </div>
      </div>
    </div>
    <camera
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @close="getClose()"
      @studentface="postStudentCompareFace"
    ></camera>
    <!-- 设置学习目标 -->
    <setGoals
      v-if="setDialogVisible"
      :dialogVisible="setDialogVisible"
      :courseId="myCourseId"
      @close="setClose"
    ></setGoals>
    <!-- 日历 -->
    <punchCalendar
      v-if="calendarDialogVisible"
      :dialogVisible="calendarDialogVisible"
      :courseId="myCourseId"
      @close="calendarClose"
    ></punchCalendar>
  </div>
</template>

<script>
import camera from "@/components/canvasCamera.vue";
import { questionBankQueryQuestionCourse } from "@/api/personal";
import {
  questionBankQueryPaperList,
  questionBankQueryChapters,
  queryStudySituation,
  queryEnglishCategory,
  setWordsTask,
  queryPracticeInfo,
  querySentenceCategory,
  resetEnglishPractice,
} from "@/api/questionBank";
import { studentCompareFace } from "@/api/api";
import noData from "@/components/noData";
import setGoals from "./components/setGoals.vue";
import punchCalendar from "./components/punchCalendar.vue";

export default {
  data() {
    return {
      question: {},
      practices: [
        { id: 1, name: "套卷练习", icon: "el-icon-tickets" },
        // { id: 2, name: "随机练习", icon: "el-icon-document" },
        { id: 3, name: "章节练习", icon: "el-icon-notebook-2" },
        { id: 4, name: "名词刷题", icon: "el-icon-document" },
        // { id: 5, name: "题型练习", icon: "el-icon-reading" },
        { id: 6, name: "课后练习", icon: "el-icon-tickets" },
        { id: 7, name: "词汇", icon: "", img: "icon_en" },
        { id: 8, name: "句式分析", icon: "", img: "icon_en1" },
      ],
      practice: {},
      practicesIndex: 1,
      list: [],
      contacts: [],
      contactsIndex: 0,
      rollUps: ["全部", "模拟考试", "历年真题", "专项练习"],
      questionCategory: [
        "全部",
        "单选题",
        "多选题",
        "判断题",
        "填空题",
        "简答题",
        "论述题",
      ],
      wordsCategory: ["全部", "英语单词", "专业名词", "作品解析", "句式分析"],
      bankqcId: -1,
      wordsqcId: -1,
      qcIndex: 0,
      wordsqcIndex: 0,
      chapters: ["章节"],
      paperType: "",
      page: 1, //页码
      pageSize: 10, //每页条数
      total: 0,
      defaultProps: {
        label: "text",
        children: "children",
      },
      customColor: "#1288f4",
      dialogVisible: false,
      setDialogVisible: false,
      calendarDialogVisible: false,
      jump: {},
      user: {},
      selectVal: "",
      myCourseId: 0,
      courseList: [],
      courseActive: true,
      studySituation: {},
      refreshBtnShow: false,
    };
  },
  components: {
    camera,
    noData,
    setGoals,
    punchCalendar,
  },
  created() {
    // this.dialogVisible = localStorage.getItem("isShowFace") == "1" ? true : false;
    // console.log(localStorage.getItem("isShowFace"));
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.contacts = this.rollUps;
    this.getQuestionBankQueryQuestionCourse();
    // if (localStorage.getItem("") != "1") {
    //   this.getQuestionBankQueryQuestionCourse();
    // }
  },
  mounted() {},
  methods: {
    calendarClose() {
      this.calendarDialogVisible = false;
    },
    // 打卡日历
    goToCalendar() {
      // let url = "/H5/questionCenter/punchCalendar"
      // let obj = {
      //   UserID: this.user.Id,
      //   courseId: this.myCourseId
      // }
      // this.$router.push({ path: url, query: obj });
      this.calendarDialogVisible = true;
    },
    setClose() {
      this.setDialogVisible = false;
      console.log("tag", "");
      this.getQueryStudySituation();
    },
    // 设置学习目标
    setStudy() {
      this.setDialogVisible = true;
      // let url = "/H5/questionCenter/setGoals"
      // let obj = {
      //   UserID: this.user.Id,
      //   courseId: this.myCourseId
      // }
      // this.$router.push({ path: url, query: obj });
    },
    resetEnglishPractice() {
      let parm = {
        StudentId: this.user.Id,
        CourseId: this.myCourseId,
      };
      let that = this;
      this.$confirm("若重新学习所有的单词，刷词记录将被清空，是否重新学习?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.postResetEnglishPractice(parm);
        })
        .catch(() => {
          return false;
        });
    },
    async postResetEnglishPractice(parm)
    {
          const res = await resetEnglishPractice(parm);
          if (res) {
            this.getQueryEnglishCategory();
            this.$message.success(res.msg);
          }
    },
    // 立即学习
    handStudy() {
      if (!this.studySituation.taskSettingEntity) {
        this.$message.error("请设置学习目标");
        return false;
      }
      this.postSetWordsTask();
    },
    // 每日任务生成 —— 进入学习时调用
    async postSetWordsTask() {
      let parm = {
        studentId: this.user.Id,
        // studentName: this.user.Id,
        courseId: this.myCourseId,
        courseName: this.selectVal,
        taskDate: this.common.dataYMD(new Date()),
        newWordsCount: this.studySituation.taskSettingEntity.NewWordsCount,
        checkWordsCount: this.studySituation.taskSettingEntity.CheckWordsCount,
      };
      const res = await setWordsTask(parm);
      if (res.success === true) {
        let obj = {
          UserID: this.user.Id,
          courseId: this.myCourseId,
          title: "立即学习",
          type: 1,
          taskId: res.response.Id,
          newWordsCount: this.studySituation.taskSettingEntity.NewWordsCount,
          checkWordsCount:
            this.studySituation.taskSettingEntity.CheckWordsCount,
        };
        this.goVocabulary(obj);
      } else {
        this.$message.error(res.msg);
      }
    },
    goVocabulary(obj) {
      let url = "/onlineQuestionBank/vocabulary";
      this.$router.push({ path: url, query: obj });
    },
    // // 查询学习信息
    // async getQueryStudySituation () {
    //   let parm =
    //     "?courseId=" +
    //     this.myCourseId +
    //     "&studentId=" +
    //     this.user.Id
    //   const res = await queryStudySituation(parm);
    //   if (res.success == true) {
    //     this.studySituation = res.response
    //   }
    // },
    // // 获取单词分类
    // async getQueryEnglishCategory () {
    //   let parm =
    //     "?courseId=" +
    //     this.myCourseId +
    //     "&studentId=" +
    //     this.user.Id
    //   const res = await queryEnglishCategory(parm);
    //   if (res.success == true) {
    //     this.list = res.response
    //   }
    // },
    // 获取单词分类
    async getQueryEnglishCategory() {
      let parm = "?courseId=" + this.myCourseId + "&studentId=" + this.user.Id;
      const res = await queryEnglishCategory(parm);
      let doAllCount = 0;
      if (res.success == true) {
        this.list = res.response;
        res.response.forEach((element) => {
          if (element.AllCount == element.DoCount) {
            doAllCount++;
          }
        });
        // this.refreshBtnShow = true;
        this.refreshBtnShow = doAllCount == res.response.length;
        console.log(this.refreshBtnShow);
      }
    },
    // 查询学习信息
    async getQueryStudySituation() {
      let parm = "?courseId=" + this.myCourseId + "&studentId=" + this.user.Id;
      const res = await queryStudySituation(parm);
      if (res.success == true) {
        this.studySituation = res.response;
      }
    },
    // // 获取单词分类
    // async getQueryEnglishCategory () {
    //   let parm =
    //     "?courseId=" +
    //     this.myCourseId +
    //     "&studentId=" +
    //     this.user.Id
    //   const res = await queryEnglishCategory(parm);
    //   if (res.success == true) {
    //     this.list = res.response
    //   }
    // },
    // 词汇跳转
    parcticeClickVocabulary(obj) {
      if (obj.DoCount >= obj.AllCount) {
        this.$message.error("暂无更多答题");
        return;
      }
      let ob = {
        UserID: this.user.Id,
        courseId: this.myCourseId,
        title: obj.CategroyName,
        CategroryId: obj.CategroryId,
        type: 2,
      };
      this.goVocabulary(ob);
    },
    // 句式分析跳转
    parcticeClickPattern(obj) {
      if (obj.DoCount >= obj.AllCount) {
        this.$message.error("暂无更多答题");
        return;
      }
      let path = "/onlineQuestionBank/translate";
      let ob = {
        UserID: this.user.Id,
        courseId: this.myCourseId,
        CategroryId: obj.CategroryId,
        type: 0,
        exercises: 1,
      };
      this.$router.push({ path: path, query: ob });
    },
    // 词汇与语法跳转
    parcticeClickAfter(obj) {
      if (obj.DoQuestionCount >= obj.AllQuestionCount) {
        this.$message.error("暂无更多答题");
        return;
      }
      let path = "",
        ob = {};
      ob = {
        UserID: this.user.Id,
        courseId: this.myCourseId,
      };
      if (obj.QuestionCategory == -1) {
        path = "/onlineQuestionBank/lexicalGrammar";
        ob.exercises = 0;
      } else if (obj.QuestionCategory == 1) {
        path = "/onlineQuestionBank/translate";
        ob.type = 1;
        ob.exercises = 0;
      } else if (obj.QuestionCategory == 2) {
        path = "/onlineQuestionBank/writing";
        ob.type = 2;
        ob.exercises = 0;
      }
      this.$router.push({ path: path, query: ob });
    },
    // 句式分析分类
    async getQuerySentenceCategory() {
      let parm = "?courseId=" + this.myCourseId + "&studentId=" + this.user.Id;
      const res = await querySentenceCategory(parm);
      if (res.success == true) {
        this.list = res.response;
      }
    },
    // 课后练习
    async getQueryPracticeInfo() {
      let parm = "?courseId=" + this.myCourseId + "&studentId=" + this.user.Id;
      const res = await queryPracticeInfo(parm);
      if (res.success == true) {
        this.list = res.response;
      }
    },
    getClose() {
      this.dialogVisible = false;
    },
    // 人脸识别
    async postStudentCompareFace(flag) {
      // const formData = new FormData();
      // formData.append("file", file);
      // const res = await studentCompareFace(formData);
      if (flag == true) {
        // this.$message.success(res.msg);
        // this.$router.push({ path: this.jump.url, query: this.jump.ob });
        localStorage.setItem("isShowFace", 0);
        this.getQuestionBankQueryQuestionCourse();
        this.dialogVisible = false;
      } else {
        this.$message.error("人脸识别失败，请刷新后重试");
        this.dialogVisible = false;
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
    },
    handleNodeClick(obj) {
      let ob = {};
      if (obj.li_attr.questionCount > 0) {
        if (this.practicesIndex == 3) {
          ob = {
            Id: obj.id,
            CourseId: this.myCourseId,
            Name: obj.text,
            UserID: this.user.Id,
            questionCategory: this.bankqcId,
          };
          this.jump.url = "/onlineQuestionBank/chapterExercises";
        } else if (this.practicesIndex >= 4) {
          ob = {
            CourseId: this.myCourseId,
            ChapterId: obj.id,
            Name: obj.text,
            UserID: this.user.Id,
            questionCategory: this.wordsqcId,
          };
          this.jump.url = "/onlineQuestionBank/recitationMode";
        }
        this.jump.ob = ob;
        this.$router.push({ path: this.jump.url, query: this.jump.ob });
        //this.dialogVisible = true;
      }
    },
    parcticeClick(obj) {
      // let ob = {
      //   Id: obj.Id,
      // };
      // this.$router.push({
      //   path: "/onlineQuestionBank/practiceDetails",
      //   query: ob,
      // });
      console.log(this.courseActive);
      if (!this.courseActive) {
        this.$message.error("当前课程不在有效期内，不能练习");
        return false;
      }
      let ob = {
        Id: obj.Id,
        Name: obj.Name,
        UserID: this.user.Id,
      };
      this.jump.url = "/onlineQuestionBank/answer";
      this.jump.ob = ob;
      this.$router.push({ path: this.jump.url, query: this.jump.ob });
      // if (obj.Category == 2) {
      //   this.$router.push({ path: this.jump.url, query: this.jump.ob });
      // } else {
      //   this.dialogVisible = true;
      // }
    },
    //考题类型
    contactsClick(obj = {}, i) {
      this.contactsIndex = i;
      if (this.contactsIndex == 0) {
        this.paperType = "";
      } else if (this.contactsIndex == 1) {
        this.paperType = 0;
      } else if (this.contactsIndex == 2) {
        this.paperType = 1;
      } else if (this.contactsIndex == 3) {
        this.paperType = 2;
      }

      this.init();
    },
    qcategoryClick(obj = {}, i, category) {
      if (category == 1) {
        this.bankqcId = i - 1;
        this.qcIndex = i;
      }
      if (category == 2) {
        this.wordsqcId = i - 1;
        this.wordsqcIndex = i;
      }
      this.getQuestionBankQueryChapters();
    },
    //练习模式
    practicesClick(obj) {
      if (this.user == null) {
        this.$message.error("请先登陆");
        return false;
      }
      this.practice = obj;
      this.page = 1;
      this.practicesIndex = obj.id;
      this.contactsIndex = 0;
      this.list = [];
      let ob = {
        Id: this.myCourseId,
        Name: this.selectVal,
        UserID: this.user.Id,
      };
      // 1套卷练习2随机练习3章节练习4名词刷题5题型练习
      if (obj.id == 1) {
        this.contacts = this.rollUps;
        this.paperType = "";
        this.init();
      } else if (obj.id == 2) {
        this.$router.push({
          path: "/onlineQuestionBank/randomAnswer",
          query: ob,
        });
      } else if (obj.id == 3 || obj.id == 4) {
        this.contacts = this.chapters;
        this.getQuestionBankQueryChapters();
      } else if (obj.id == 4) {
        // this.jump.url = "/onlineQuestionBank/recitationMode";
        // this.jump.ob = ob;
        // // this.$router.push({ path: this.jump.url, query: this.jump.ob });
        // this.dialogVisible = true;
      } else if (obj.id == 5) {
        this.paperType = 2;
        this.init();
      } else if (obj.id == 6) {
        this.getQueryPracticeInfo();
      } else if (obj.id == 7) {
        this.getQueryStudySituation();
        this.getQueryEnglishCategory();
      } else if (obj.id == 8) {
        this.getQuerySentenceCategory();
      }
    },
    async getQuestionBankQueryChapters() {
      let obj = "";
      if (this.practicesIndex == 3) {
        obj =
          "?courseId=" + this.myCourseId + "&questionCategory=" + this.bankqcId;
      } else if (this.practicesIndex >= 4) {
        obj =
          "?courseId=" +
          this.myCourseId +
          "&isWords=true" +
          "&questionCategory=" +
          this.wordsqcId;
      }
      const res = await questionBankQueryChapters(obj);
      if (res.success === true) {
        this.list = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
    async init() {
      this.question = this.$route.query;
      let parm = {
        studentId: this.user.Id,
        courseId: this.myCourseId,
        pageIndex: this.page,
        pageSize: this.pageSize,
        PaperType: this.paperType, //试卷类型，不传时全部，0模拟试卷，1真题
      };
      const res = await questionBankQueryPaperList(parm);
      if (res.success === true) {
        this.list = res.response.data;
        this.total = res.response.dataCount;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getQuestionBankQueryQuestionCourse() {
      const res = await questionBankQueryQuestionCourse();
      if (res.success === true) {
        this.courseList = res.response;
        console.log(this.courseList);
        this.CourseId = this.courseList[0].CourseId;
        this.selectVal = this.courseList[0].CourseName;
        this.myCourseId = this.courseList[0].CourseId;
        this.courseActive = this.courseList[0].IsActive;
        this.$router.push({
          path: this.$route.path,
          query: { CourseId: this.CourseId },
        });
        this.init();
      } else {
        this.$message.error(res.msg);
      }
    },
    handleChange(obj) {
      // console.log(obj)
      this.selectVal = obj.CourseName;
      this.CourseId = obj.CourseId;
      this.myCourseId = this.CourseId;
      this.courseActive = obj.IsActive;
      if (obj.CourseName.includes("英语")) {
        this.wordsqcId = 0;
        this.practicesIndex = 6;
        this.getQueryPracticeInfo();
      } else {
        if (this.practicesIndex > 4) {
          this.practicesIndex = 4;
          this.contacts = this.rollUps;
        }
      }
      if (this.practicesIndex == 3 || this.practicesIndex == 4) {
        this.qcIndex = 0;
        this.wordsqcIndex = 0;
        this.getQuestionBankQueryChapters();
      } else if (this.practicesIndex == 6) {
        this.getQueryPracticeInfo();
      } else {
        this.init();
      }
      //this.$router.push({ path: this.$route.path, query: {CourseId:this.CourseId}});
      //this.init();
      //this.practicesClick(this.practice);
    },
  },
};
</script>

<style lang="less" scoped>
.question_details {
  font-size: 14px;
  .cont {
    .title {
      line-height: 60px;
      background-color: #fff;
      padding: 0 30px;
      margin: 10px 0;
      font-size: 16px;
    }
    .practice {
      cursor: pointer;
      overflow: hidden;
      li {
        text-align: center;
        float: left;
        // padding: 25px 0 20px;
        width: 120px;
        height: 40px;
        line-height: 40px;
        background-color: #f5f5f5;
        color: #666666;
        border-radius: 20px;
        margin-left: 20px;
        font-size: 13px;
        i {
          font-size: 16px;
          margin-right: 8px;
          // vertical-align: middle;
        }
        img {
          margin-right: 8px;
          vertical-align: text-bottom;
        }
        p {
          margin-top: 10px;
        }
      }
      .active {
        color: #1288f4;
        background-color: #e2ebfe;
      }
    }
    .rollUp {
      .rollUp_top {
        padding: 0 30px;
        margin: 10px 0;
        background-color: #fff;
        span {
          display: inline-block;
          width: 70px;
          text-align: center;
          margin-right: 15px;
          line-height: 60px;
          cursor: pointer;
        }
        .active {
          color: #1288f4;
          position: relative;
          &::before {
            content: "";
            width: 100%;
            height: 3px;
            background-color: #1288f4;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
      .rollUp_top_first {
        background-color: #fff;
        margin: 10px;
        padding: 10px 15px;
        border-radius: 10px;
        font-size: 13px;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        .icon_right {
          margin-left: 10px;
          color: #c3c5c7;
          font-size: 12px;
        }
        .day {
          cursor: pointer;
          span {
            font-size: 34px;
            font-weight: bold;
            font-family: DIN;
            line-height: 1;
          }
        }
        .set {
          display: flex;
          font-weight: bold;
          font-size: 16px;
          div {
            cursor: pointer;
            &:first-child {
              margin: 0 80px;
              // border-right: 1px solid #c8c7c7;
            }
          }
          img {
            vertical-align: text-bottom;
            margin-left: 5px;
          }
          span {
            color: #c8c7c7;
            margin: 0 15px;
          }
        }
      }
      .rollUp_cont {
        // padding: 0 40px;
        padding: 20px 30px;
        margin: 10px 0;
        background-color: #fff;
        .el-tree {
          padding: 0 30px;
          .custom-tree-node {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            padding-right: 8px;
            .tree_right {
              display: flex;
              /deep/.el-progress {
                width: 120px;
                .el-progress-bar {
                  padding-right: 5px !important;
                }
                .el-progress__text {
                  display: none;
                }
              }
              .proportion {
                min-width: 45px;
                text-align: right;
              }
            }
          }
        }
        .el-row {
          line-height: 40px;
          border-bottom: 1px solid #efefef;
          .el-col {
            cursor: pointer;
            &:first-child {
              padding-left: 40px;
            }
            &:last-child {
              padding-right: 40px;
            }
          }
        }
        .text_align_right {
          margin-top: 8px;
        }
      }
      .rollUp_cont1 {
        .name {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          line-height: 1;
          margin: 20px 0 10px;
        }
        .count {
          color: #999999;
          line-height: 1;
          margin-bottom: 20px;
        }
        .el-row {
          .el-col {
            cursor: pointer;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}
/deep/.el-tree-node__content {
  min-height: 60px;
  border-bottom: 1px solid #e6e9ed;
}
/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}
.courseList {
  line-height: 30px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
}
.courseList_hover {
  width: 217px;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  padding: 0 10px;
  border: 1px solid #dcdfe6;
  color: #606266;
  font-size: 14px;
}
.personal_title {
  height: auto;
  padding: 18px 30px;
  display: flex;
  justify-content: space-between;
}
</style>