<template>
  <el-dialog
    title="每日学习任务"
    :visible.sync="dialogVisible"
    width="500px"
    :before-close="handleClose"
  >
    <div class="setGoals">
      <div class="cont">
        <!-- <div class="title">每日学习任务</div> -->
        <div class="scfx">
          <div class="cy">
            新词
            <el-input v-model="newWordsCount" placeholder="新词个数"></el-input>
          </div>
          <div class="cy">
            复习
            <el-input
              v-model="checkWordsCount"
              placeholder="复习个数"
            ></el-input>
          </div>
        </div>
        <van-picker :columns="columns" @change="onChange" />
      </div>
      <div class="tips">新任务量低于当前已学任务量，要明天生效哦</div>
      <div class="btn_que" style="margin: 30px 0">
        <div class="act" @click="handleComplete">完成设置</div>
      </div>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">关 闭</el-button>
    </span> -->
  </el-dialog>
</template>
<script>
import {
  queryStudySituation,
  setTaskSetting
} from "@/api/questionBank";
export default {
  props: ["dialogVisible", "courseId"],
  components: {},
  computed: {},
  watch: {},
  data () {
    return {
      value: "",
      columns: [
        { values: ["5", "10", "15", "20", "25", "30"] },
        { values: ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100'] },
      ],
      studySituation: {},
      newWordsCount: "",
      checkWordsCount: "",
      user: JSON.parse(localStorage.getItem("userInfo"))
    };
  },
  mounted () {
    this.route = this.$route.query;
    console.log(this.courseId, '')
    this.getQueryStudySituation()
  },
  methods: {
    handleClose () {
      this.$emit("close");
    },
    // 完成
    async handleComplete () {
      if (!this.newWordsCount || !this.newWordsCount) {
        this.$message.error('请设置学习目标');
        return false;
      }
      let parm = {
        studentId: this.user.Id,
        courseId: this.courseId,
        newWordsCount: this.newWordsCount,
        checkWordsCount: this.checkWordsCount,
      }
      const res = await setTaskSetting(parm);
      if (res.success === true) {
        this.$message.success(res.msg);
        // this.$router.go(-1)
        // this.$emit("setClose");
        this.handleClose()
      } else {
        this.$message.error(res.msg);
      }
    },
    onChange (picker, values) {
      this.newWordsCount = values[0]
      this.checkWordsCount = values[1]
      // console.log(picker, values, '')
      // picker.setColumnValues(1, cities[values[0]]);
    },
    // 查询学习信息
    async getQueryStudySituation () {
      let parm =
        "?courseId=" +
        this.courseId +
        "&studentId=" +
        this.user.Id
      const res = await queryStudySituation(parm);
      if (res.success == true) {
        this.newWordsCount = res.response.taskSettingEntity?.NewWordsCount
        this.checkWordsCount = res.response.taskSettingEntity?.CheckWordsCount
      }
    },
  }
};
</script>
<style lang="less" scoped>
.setGoals {
  // min-height: 100vh;
  .cont {
    margin: 15px 20px;
    background-color: #fff;
    padding-bottom: 30px;
    .title {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      padding: 20px 0;
    }
    .scfx {
      font-size: 15px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 20px;
      .cy {
        font-size: 15px;
        display: flex;
        align-content: center;
        align-items: center;
      }
      // div + div {
      //   margin-left: 20px;
      // }
      /deep/.el-input {
        width: 100px;
        .el-input__inner {
          padding: 0;
          font-size: 14px;
          text-align: center;
          border: none;
          border-bottom: 1px solid #dcdfe6;
        }
      }
      span {
        color: #c8c7c7;
      }
    }
  }
  .tips {
    font-size: 14px;
    color: #558af9;
    margin: 0 20px;
    text-align: center;
  }
}
</style>
