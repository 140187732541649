import { render, staticRenderFns } from "./punchCalendar.vue?vue&type=template&id=6aed7e5c&scoped=true&"
import script from "./punchCalendar.vue?vue&type=script&lang=js&"
export * from "./punchCalendar.vue?vue&type=script&lang=js&"
import style0 from "./punchCalendar.vue?vue&type=style&index=0&id=6aed7e5c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aed7e5c",
  null
  
)

export default component.exports